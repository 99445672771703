<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid  v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Empresas'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft" v-if="permisos.create">
                            <div class="botonAgregar">
                                <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right
                                    rounded 
                                    @click="abrirModal('create')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Razón social"
                                    v-model="razon_social"
                                ></v-text-field>
                            </v-col>
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                        no-data-text="Datos no disponibles"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </template>
                            <v-col xs="12" sm="6" md="6" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="RFC"
                                    v-model="rfc"
                                ></v-text-field>
                            </v-col>
                            <!-- <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="tiposEmpresas"
                                    item-text="nombre"
                                    item-value="valor"
                                    label="Tipo de empresa"
                                    persistent-hint
                                    v-model="valorTipoEmpresa"
                                ></v-autocomplete>
                            </v-col> -->
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{item.razon_social}}</div></td>
                                <td><div>{{item.rfc}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="abrirModal('update', item)" class="botonHover" icon fab dark small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="deleteEmpresa(item)" class="botonHover" icon fab dark small>
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
        <template> 
            <ModalEmpresa 
                :title="tituloEmpresa"
                :dialog="dialog_empresa"
                :objEmpresa="datosEmpresa"
                :action="accionEmpresa"
                @cerrarModal="cerrarModal"
                :key="componentKey"
            />
        </template>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import ComponentOne from './Modal/ModalEmpresa.vue';
import Notify from '@/plugins/notify';
import queries from '@/queries/Empresas';
import apiEmpresas from "@/api/empresas";
import clientesApi from "@/api/clientes";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ModalEmpresa from './Modal/ModalEmpresa.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
        ModalEmpresa
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Razón Social',
                    name: 'razon_social',
                    filterable: true
                },
                {
                    label: 'RFC',
                    name: 'rfc',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            tiposEmpresas   : [{nombre:"Empresa pagadora", valor:"pagadora"},{nombre:"Empresa no pagadora",valor:"noPagadora"}],
            valorTipoEmpresa: null,
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            rfc             : null,
            clientes        : [],
            rol             : null,
            cliente_value   : null,
            nombre          : null,
            fecha_inicio    : null,
            menu_inicio     : false,
            fecha_final     : null,
            menu_final      : false,
            razon_social    : null,
            datosLogin      : null,
            rol             : null,
            datosEmpresa    : null,
            tituloEmpresa   : null,
            accionEmpresa   : null,
            dialog_empresa  : false,
            componentKey    : 0,


            permisos        : {
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
    },
    methods: {
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            if (this.rol == "root") {
                this.getClientes();
            }

            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
            }

            if (this.rol == "admin-empresa") {
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        openModal(type, itemEmpresa = null) {
            let resolucion = screen.height;
            let alto = screen.height * 0.70;
            if (resolucion < 850) {
                alto = resolucion * 0.76;
            }

            const title = type == "update" ? "Actualizar Empresa" : "Registrar Empresa";

            this.$modal.show(ComponentOne, {
                title: title,
                objEmpresa: itemEmpresa,
                action: type
            }, {
                name: 'empresaModal',
                height: alto,
                width: 900,
                scrollable: true
            }, {
                'before-close': (event) => {
                    this.isLoading = true;
                    this.$apollo.queries.empresas.refetch();
                }
            });
        },
        deleteEmpresa(item) {
            Notify.Alert(
                "¿Estás seguro de eliminar esta empresa?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiEmpresas.deleteEmpresa(item.id).then(response => {
                        Notify.Success("Empresa Eliminada", "La empresa ha sido eliminada satisfactoriamente");
                        this.$apollo.queries.empresas.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
            this.$apollo.queries.empresas;
        },
        async getClientes(clientes = null) {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
                //this.finaliza_parametros();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        resetValues() {
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
                
            this.$nextTick(() => {
                this.cliente_value      = null;
                this.nombre = null;
                this.fecha_inicio = null;
                this.fecha_final = null;
                this.valorTipoEmpresa = null;
                this.rfc = null;
            });
        },
        async loadModalData() {
            this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
                this.cliente_value = null;
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
        },
        setFilters() {
            this.filters = { AND:[]};
            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let rfc = this.rfc;
            let valorTipoEmpresa = this.valorTipoEmpresa;
            let razonSocial = this.razon_social;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
            } 

            this.isLoading = true;
            let dates = 0;

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }

            if(razonSocial != null && razonSocial != undefined && razonSocial != ""){
                this.filters.AND.push({column:'RAZON_SOCIAL', operator:'ILIKE', value:"%"+razonSocial+"%"});
            }

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente})
            }

            if(rfc != null && rfc != undefined && rfc != ""){
                this.filters.AND.push({column:'RFC', operator:'ILIKE', value:"%"+rfc+"%"});
            }

            if(valorTipoEmpresa != null && valorTipoEmpresa != undefined && valorTipoEmpresa != ""){
                if(valorTipoEmpresa == 'pagadora'){
                    this.filters.AND.push({column:'SOLO_PAGOS', value: true});
                }
                else{
                    this.filters.AND.push({column:'SOLO_PAGOS', value: false});
                }
            }

            this.$apollo.queries.empresas.refetch();
            this.resetValues();
        },
        abrirModal(accion, item = null){
            this.componentKey += 1;  
            this.datosEmpresa    = item;
            this.tituloEmpresa   = accion == "update" ? "Actualizar Empresa" : "Registrar Empresa";
            this.accionEmpresa   = accion;
            this.dialog_empresa =  true;
        },
        cerrarModal(data){
            if(data == "cancelar"){
                this.dialog_empresa =  false;
            }
            if(data == "guardarCerrar"){
                this.dialog_empresa =  false;
                this.isLoading = true;
                this.$apollo.queries.empresas.refetch();
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("empresas.store")
            this.permisos.update = permisosStorage.includes("empresas.update")
            this.permisos.delete = permisosStorage.includes("empresas.delete")
        },

    },
    mounted(){
        this.$apollo.queries.empresas.skip = false;
    },
    created () {
        this.listar();
        this.permisosVista()
    },
    apollo: {
        empresas: {
            query       : queries.empresasTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.empresas;
            }
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .titulo {
        font-family: 'Montserrat' !important;
        font-size: 28px !important;
        font-weight: 700 !important;
        color: #0f3043 !important;
        letter-spacing: -0.68px;
        line-height: 34px;
    }
    .btnGuardar {
        font-family: 'Montserrat';
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: #183799 !important;
        border-radius: 23px 23px 23px 23px;
        color: white !important;
        width: 70% !important;
        height: 40px !important;
    }
    .btnCerrar {
        font-family: 'Montserrat';
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: white !important;
        border-radius: 23px 23px 23px 23px;
        color: rgba(121, 141, 152, 0.6);
        width: 70% !important;
        height: 40px !important;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .tipoLetra700Azul {
        font-weight: 700;
        letter-spacing: -0.34px;
    }
    .titleRango {
        font-family: Montserrat !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        color: #25282b !important;
    }
    
</style>

